import React, { useEffect } from 'react'
import { NoResults } from '../search/NoResults'
import { PreviewCard } from '../shared'
import { BioButton } from '../shared'
import { DefaultApplication } from './DefaultApplication'

export const JobTypeGrid = ({
  applicationCardHeadline,
  applicationCardText,
  careerContext,
  count,
  items,
  noSearchResultsText,
  searchCount,
  searchItems,
  setJobCount,
  searchLimit
}) => {
  const aggrItems =
    items?.value_aggregation?.buckets?.buckets.slice(0, searchLimit) || items
  useEffect(() => {
    if (aggrItems?.length > 0) setJobCount(aggrItems?.length)
  }, [aggrItems])
  const pathPrefix = careerContext === 1 ? `/karriere/` : `/ausbildung/`
  return (
    <>
      {!aggrItems?.length && <NoResults content={noSearchResultsText} />}
      <div className="flex flex-wrap items-stretch newCards">
        {aggrItems.map(vacancy => {
          const countLabel =
            vacancy.doc_count === 1 ? 'offene Stelle' : 'offene Stellen'

          const jobType =
            vacancy?.jobtype?.hits?.hits?.[0]?._source?.jobType ||
            vacancy.nodes[0]?.jobType

          if (!jobType) return null
          return (
            <PreviewCard
              key={jobType._id}
              path={`${pathPrefix}${jobType?.slug?.current || jobType?.slug}`}
              previewImage={jobType?.image}
              searchItems={{
                ...searchItems,
                index: 'vacancy',
                jobTypeId: jobType._id
              }}>
              <h3 className="w-3/4 mt-16 mb-20 text-center">{jobType.title}</h3>
              <div className="flex-1 flex flex-col justify-end">
                <p className="w-5/6 mx-auto text-center text-md">{`${vacancy.doc_count} ${countLabel}`}</p>
                <div className="text-center pt-24 pb-10">
                  <BioButton
                    style="isSmall isWide"
                    button={{ buttonCta: 'Stellenbeschreibung lesen' }}
                  />
                </div>
              </div>
            </PreviewCard>
          )
        })}

        {careerContext === 1 && (count <= searchCount || searchCount === 0) && (
          <DefaultApplication
            applicationCardHeadline={applicationCardHeadline}
            applicationCardText={applicationCardText}
          />
        )}
      </div>
    </>
  )
}
