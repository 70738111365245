import React from 'react'
import { PreviewCard } from '../shared'
import { BioButton } from '../shared'

export const DefaultApplication = ({
  applicationCardHeadline,
  applicationCardText
}) => (
  <PreviewCard
    path="/karriere/initiativbewerbung"
    bgColor="bg-purple"
    color="text-veryLightGrey">
    <h3 className="w-3/4 mt-16 mb-20 text-center">{applicationCardHeadline}</h3>
    <div className="flex-1 flex flex-col justify-end">
      <p className="w-5/6 mx-auto text-center text-md">{applicationCardText}</p>
      <div className="text-center pt-24 pb-10">
        <BioButton
          style="isSmall isWide"
          button={{ buttonCta: 'Jetzt Bewerben' }}
        />
      </div>
    </div>
  </PreviewCard>
)
